import { createEnv } from "@t3-oss/env-nextjs";
import "dayjs/locale/sv";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_URL: z.string().min(1),
    // REDIS_HOST: z.string().default("localhost"),
    // REDIS_PORT: z.coerce.number().default(6379),
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
    GOOGLE_CLIENT_ID: z.string(),
    GOOGLE_CLIENT_SECRET: z.string(),
    GOOGLE_REDIRECT_URI: z
      .string()
      .url()
      .default("http://localhost:3000/login/google/callback"),
    OPENAI_API_KEY: z.string(),
    OPENAI_ORG: z.string().optional(),
    PLAUSIBLE_API_KEY: z.string().optional(),
    PAYLOAD_URL: z.string().default("https://cms.nextgenclassroom.se"),
    SHARED_SECRET: z.string().default("foobar"),
    TRANSLATIONS_SECRET: z.string().default("foobar"),

    AZURE_CLIENT_ID: z.string(),
    AZURE_CLIENT_SECRET: z.string(),
    AZURE_REDIRECT_URI: z
      .string()
      .url()
      .default("http://localhost:3000/login/azure/callback"),

    QUEUE_REDIS_URL: z.string().url(),
    QUEUE_REDIS_TOKEN: z.string().min(1),

    QSTASH_TOKEN: z.string().min(1),
    HEIGHT_SECRET: z.string().min(1).optional(),
    DISCORD_ALERTS_WEBHOOK: z.string().url(),

    S3_USER_IMAGE_ENDPOINT: z.string().url(),
    S3_USER_IMAGE_ACCESS_KEY_ID: z.string().min(1),
    S3_USER_IMAGE_SECRET_ACCESS_KEY: z.string().min(1),
    S3_USER_IMAGE_BUCKET: z.string().min(1),
    S3_USER_IMAGE_REGION: z.string().min(1),

    S3_AUDIO_CLIPS_ENDPOINT: z.string().url(),
    S3_AUDIO_CLIPS_ACCESS_KEY_ID: z.string().min(1),
    S3_AUDIO_CLIPS_SECRET_ACCESS_KEY: z.string().min(1),
    S3_AUDIO_CLIPS_BUCKET: z.string().min(1),
    S3_AUDIO_CLIPS_REGION: z.string().min(1),

    S3_WORD2_IMAGES_ENDPOINT: z.string().url(),
    S3_WORD2_IMAGES_ACCESS_KEY_ID: z.string().min(1),
    S3_WORD2_IMAGES_SECRET_ACCESS_KEY: z.string().min(1),
    S3_WORD2_IMAGES_BUCKET: z.string().min(1),
    S3_WORD2_IMAGES_REGION: z.string().min(1),

    EMAIL_TOKEN: z.string().min(1).optional(),
    EMAIL_REGION: z.string().min(1).optional(),
    EMAIL_PROJECT_ID: z.string().uuid().optional(),
    EMAIL_DOMAIN: z.string().min(1).optional(),

    SKOLON_CLIENT_ID: z.string().min(1),
    SKOLON_CLIENT_SECRET: z.string().min(1),
    SKOLON_REDIRECT_URI: z.string().url(),

    ELEVENLABS_API_KEY: z.string().min(1),
    REPLICATE_API_TOKEN: z.string().min(1),

    SKIP_ENV_VALIDATION: z.coerce.boolean().default(false),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_PLAUSIBLE_DOMAIN: z.string().min(1).default("localhost"),
    NEXT_PUBLIC_APP_URL: z.string().url().default("http://localhost:3000"),
    NEXT_PUBLIC_PAYLOAD_URL: z
      .string()
      .url()
      .default("https://cms.nextgenclassroom.se"),
    NEXT_PUBLIC_NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    // REDIS_HOST: process.env.REDIS_HOST,
    // REDIS_PORT: process.env.REDIS_PORT,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
    GOOGLE_REDIRECT_URI: process.env.GOOGLE_REDIRECT_URI,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    OPENAI_ORG: process.env.OPENAI_ORG,
    PLAUSIBLE_API_KEY: process.env.PLAUSIBLE_API_KEY,
    PAYLOAD_URL: process.env.PAYLOAD_URL,
    SHARED_SECRET: process.env.SHARED_SECRET,
    TRANSLATIONS_SECRET: process.env.TRANSLATIONS_SECRET,

    QUEUE_REDIS_URL: process.env.QUEUE_REDIS_URL,
    QUEUE_REDIS_TOKEN: process.env.QUEUE_REDIS_TOKEN,
    QSTASH_TOKEN: process.env.QSTASH_TOKEN,

    HEIGHT_SECRET: process.env.HEIGHT_SECRET,
    DISCORD_ALERTS_WEBHOOK: process.env.DISCORD_ALERTS_WEBHOOK,

    AZURE_CLIENT_ID: process.env.AZURE_CLIENT_ID,
    AZURE_CLIENT_SECRET: process.env.AZURE_CLIENT_SECRET,
    AZURE_REDIRECT_URI: process.env.AZURE_REDIRECT_URI,

    NEXT_PUBLIC_PAYLOAD_URL: process.env.NEXT_PUBLIC_PAYLOAD_URL,
    NEXT_PUBLIC_PLAUSIBLE_DOMAIN: process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN,
    NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,

    S3_USER_IMAGE_ENDPOINT: process.env.S3_USER_IMAGE_ENDPOINT,
    S3_USER_IMAGE_ACCESS_KEY_ID: process.env.S3_USER_IMAGE_ACCESS_KEY_ID,
    S3_USER_IMAGE_SECRET_ACCESS_KEY:
      process.env.S3_USER_IMAGE_SECRET_ACCESS_KEY,
    S3_USER_IMAGE_BUCKET: process.env.S3_USER_IMAGE_BUCKET,
    S3_USER_IMAGE_REGION: process.env.S3_USER_IMAGE_REGION,

    S3_AUDIO_CLIPS_ENDPOINT: process.env.S3_AUDIO_CLIPS_ENDPOINT,
    S3_AUDIO_CLIPS_ACCESS_KEY_ID: process.env.S3_AUDIO_CLIPS_ACCESS_KEY_ID,
    S3_AUDIO_CLIPS_SECRET_ACCESS_KEY:
      process.env.S3_AUDIO_CLIPS_SECRET_ACCESS_KEY,
    S3_AUDIO_CLIPS_BUCKET: process.env.S3_AUDIO_CLIPS_BUCKET,
    S3_AUDIO_CLIPS_REGION: process.env.S3_AUDIO_CLIPS_REGION,

    S3_WORD2_IMAGES_ENDPOINT: process.env.S3_WORD2_IMAGES_ENDPOINT,
    S3_WORD2_IMAGES_ACCESS_KEY_ID: process.env.S3_WORD2_IMAGES_ACCESS_KEY_ID,
    S3_WORD2_IMAGES_SECRET_ACCESS_KEY:
      process.env.S3_WORD2_IMAGES_SECRET_ACCESS_KEY,
    S3_WORD2_IMAGES_BUCKET: process.env.S3_WORD2_IMAGES_BUCKET,
    S3_WORD2_IMAGES_REGION: process.env.S3_WORD2_IMAGES_REGION,

    EMAIL_TOKEN: process.env.EMAIL_TOKEN,
    EMAIL_REGION: process.env.EMAIL_REGION,
    EMAIL_PROJECT_ID: process.env.EMAIL_PROJECT_ID,
    EMAIL_DOMAIN: process.env.EMAIL_DOMAIN,

    SKOLON_CLIENT_ID: process.env.SKOLON_CLIENT_ID,
    SKOLON_CLIENT_SECRET: process.env.SKOLON_CLIENT_SECRET,
    SKOLON_REDIRECT_URI: process.env.SKOLON_REDIRECT_URI,

    ELEVENLABS_API_KEY: process.env.ELEVENLABS_API_KEY,
    REPLICATE_API_TOKEN: process.env.REPLICATE_API_TOKEN,

    SKIP_ENV_VALIDATION: process.env.SKIP_ENV_VALIDATION,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
