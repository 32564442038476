"use client";

import { useFormState } from "react-dom";

import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import { createIssue } from "~/server/actions/height";

import { LoadingButton } from "./loading-button";
import { Translation } from "./translations/component";
import { Textarea } from "./ui/textarea";
import Twemoji from "./ui/twemoji";

interface BugDialogProps {
  plainButton?: boolean;
}
export function BugDialog(props: BugDialogProps) {
  const [state, dispatch] = useFormState(createIssue, {});
  return (
    <Dialog>
      <DialogTrigger asChild>
        {props.plainButton ? (
          <Button variant="secondary">
            <Translation id="report-bug.button">Rapportera bugg</Translation>
          </Button>
        ) : (
          <span
            role="button"
            className="flex cursor-pointer flex-row gap-2 text-center text-sm leading-loose underline underline-offset-2 md:text-left"
          >
            <Translation id="report-bug.button">Rapportera bugg</Translation>{" "}
            <Twemoji emoji="🐛" height={24} width={24} />
          </span>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <Translation id="report-bug.title">Rapportera bugg</Translation>
          </DialogTitle>
        </DialogHeader>
        {state?.data ? (
          <div className="flex flex-col gap-2">
            <p className="text-center text-sm leading-loose md:text-left">
              <Translation id="report-bug.thanks">
                Tack för att du rapporterade en bugg!
              </Translation>
            </p>
            <p>
              ID: <code>{state.data}</code>
            </p>
            <DialogClose asChild>
              <Button type="button" variant="default">
                <Translation id="common.close">Stäng</Translation>
              </Button>
            </DialogClose>
          </div>
        ) : (
          <form action={dispatch}>
            <div className="flex flex-col gap-2">
              <Input
                name="name"
                placeholder="Titel"
                required
                autoComplete="off"
              />
              <Textarea
                name="description"
                placeholder="Beskrivning"
                rows={7}
                required
              />
              <input
                type="hidden"
                name="currentUrl"
                value={
                  typeof window !== "undefined"
                    ? window.location.href
                    : undefined
                }
              />
              <LoadingButton type="submit">
                <Translation id="report-bug.button-short">
                  Rapportera
                </Translation>
              </LoadingButton>
            </div>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
