"use client";

import { localeTable, type locales } from "@nextgen/shared";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useMemo } from "react";

import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import Twemoji from "../ui/twemoji";
import { setLocale } from "./actions";

interface Props {
  currentLocale: (typeof locales)[number];
  flagOnly?: boolean;
}

export function LocaleSwitcher({ currentLocale, flagOnly }: Props) {
  const currentLocaleData = localeTable[currentLocale];

  async function handleLocaleChange(locale: string) {
    await setLocale(locale as (typeof locales)[number]);
    // window.location.reload();
  }

  const sortedLocales = useMemo(() => {
    const locales = Object.entries(localeTable)
      .map(([key, value]) => ({
        locale: key,
        ...value,
      }))
      .toSorted((a, b) => a.nativeName.localeCompare(b.nativeName));

    const currentLocaleIndex = locales.findIndex(
      (locale) => locale.locale === currentLocale,
    );
    if (currentLocaleIndex > 0) {
      const [current] = locales.splice(currentLocaleIndex, 1);
      if (current) {
        locales.unshift(current);
      }
    }

    return locales;
  }, [currentLocale]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="flex items-center gap-1 font-semibold"
        >
          <Twemoji emoji={currentLocaleData.emoji} height={24} width={24} />
          {!flagOnly && (
            <>
              <span>{currentLocale.toUpperCase()}</span>
              <ChevronDownIcon className="h-4 w-4" />
            </>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="end"
        className="mx-2 origin-bottom scale-95"
      >
        {sortedLocales.map(({ nativeName, emoji, locale }) => (
          <DropdownMenuItem
            key={locale}
            onClick={() => handleLocaleChange(locale)}
          >
            <Twemoji emoji={emoji} height={24} width={24} />
            <span className="ml-2">{nativeName}</span>
            {locale === currentLocale && (
              <CheckIcon className="ml-auto h-4 w-4" />
            )}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
